import React from 'react';
import '../App.css';
import icon from '../navDropIcon.svg'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem 
} from 'reactstrap';

const navBarStyle = {
  color: '#FFD900',
  fontFamily: 'DIN Alternate',
  fontSize: '20px'
}

const logoStyle = {
  height: '35px',
  width: '35px',
}

// const useScroll = () => {
//   const ref = useRef(null)
//   const executeScroll = () => {
//     window.scrollTo(0, ref.current.offsetTop)
//   }
//   const htmlElementAttributes = { ref }

//   return [executeScroll, htmlElementAttributes]
// }

const navbarTogglerStyle = {
  borderColor: navBarStyle['color'],
}
// // const background = "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#FFD900' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"
// const navbarTogglerIconStyle = {
//   backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#FFD900' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")`
// }

// export default () =>
//   <svg >
//   </svg>

  
export default class MyNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  
  render() {
    return (
      <div>
        <Navbar style={{ backgroundColor: '#002164'}} dark expand="md">
          <NavbarBrand style={navBarStyle} href="/"> <img style={logoStyle} src="../img/jcat_logo_icon.png" alt="" /> <span style={{marginLeft: '8px', fontSize: '24px' }}>jcat.bike</span> </NavbarBrand>
          <NavbarToggler onClick={this.toggle} style={navbarTogglerStyle}> <img src={icon} className="nav-icon" alt="logo" /> </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink style={navBarStyle} href="#micro">/micro</NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={navBarStyle} href="#about">about</NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={navBarStyle} href="#contact">contact</NavLink>
              </NavItem>
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle style={navBarStyle} nav caret>
                  Options
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    Option 1
                  </DropdownItem>
                  <DropdownItem>
                    Option 2
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    Reset
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}