import React from 'react';
import MyCarousel from './components/MyCarousel';

const descriptionHeadingStyle = {
  color: '#FFD900',
  fontFamily: 'DIN Alternate',
  fontSize: '72px'
}

const bikeDescriptionStyle = {
  fontFamily: 'Helvetica',
  fontSize: '16px',
  backgroundColor: '#EEEEEE',
}

const sectionHeadingStyle = {
  marginTop: '20px',
  marginBottom: '20px',
  fontWeight: 'bold',
  fontSize: '24px'
}

const boldFont = {
  fontWeight: 'bold',
}

const BikeDescription = () => {
  return(
  <section id="micro" style={bikeDescriptionStyle}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center" style={{ marginTop: '40px', marginBottom: '30px'}}>
          <h2 className="section-heading" style={descriptionHeadingStyle}> /micro</h2>
        </div>
        <div className="col-lg-11 text-left">
          The /micro folding bike was designed for both short and long distance hybrid commutes, weekend rides, and light duty bicycle touring.
          <br/>
          <br/>
          To fulfill these roles, the bike needed to easily fit at a rider's feet on public transportation, stable and strong enough to haul gear for commuting or bicycle touring, while still being zippy and fun to ride.
          <br/>
          <br/>
          We're quite happy with the results, and for these purposes, we've never ridden a better bike.
          <br/>
            <div style={sectionHeadingStyle}>Features:</div>
          <ul>
            <li> 
              Geared by a reliable and reputable Sturmey Archer S-RF3 internally geared hub with a wide 177% range with 33% steps between gears (middle gear ratio of 1:1), enough to conquer most hills while still keeping up with other cycling traffic.
            </li>
            <li> 
              Smaller wheels are stronger and lighter, and with 36 spokes these wheels are near “bombproof”.
            </li>
            <li> 
              Fully adjustable seat height AND handlebar height, allowing one bike to fit anyone from 4'8" to 6'4" (142-193cm) without compromise. This is not possible with a traditional bike due to frame dimension constraints. This and the combination of a durable frame and wheels make the micro a perfect bike for guests.
            </li>
            <li>
              A pre-installed rack, and fenders for our Seattle rain. The rack is capable of holding up to two panniers (with a tent/sleeping bag on top!) with small roller wheels to allow the bike to be easily rolled when folded.
            </li>
          </ul>
          Available locally in the Greenwood neighborhood of Seattle for $699 + tax.
          If you have any questions or are interested in setting up a riding appointment, contact us <a className="js-scroll-trigger" href="#contact">below.</a>
          <div style={sectionHeadingStyle}>Technical details: </div>
          <ul>
              <li> <span style={boldFont}>Weight:</span> 26.4lbs (12kg) </li>
              <li> <span style={boldFont}>Frame material:</span> Aluminum </li>
              <li> <span style={boldFont}>Internally geared hub:</span> 177% range 3 speed Sturmey Archer S-RF3 </li>
              <li> <span style={boldFont}>Stock tires:</span> Cheng Shin C1698 16x1 3/8”  </li>
              <li> <span style={boldFont}>Rim:</span> 16"x1.50x13Gx36H, alloy. </li>
              <li> <span style={boldFont}>Brakes:</span> Caliper </li>
              <li> <span style={boldFont}>Pedals:</span> Folding </li>
          </ul>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </section>
  )
}

const aboutUsProfileStyle = {
  height: '225px',
  width: '225px',
  border: "7px solid #fff",

}

const teamMemberStyle = {
  textAlign: "center",

}

const aboutUsHeadingStyle = {
  marginTop: "45px",
  marginBottom: "30px",
  fontSize: "48px",
  fontFamily: 'DIN Alternate'
}

const AboutUs = () => {
  return(
    <section id="about" style={{ backgroundColor:"#F5F4EF"}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading" style={aboutUsHeadingStyle}>About Us</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
            <div style={teamMemberStyle} className="team-member">
              <img style={aboutUsProfileStyle} className="mx-auto rounded-circle" src="../img/ian_icon7.jpg" alt=""/>
            <h4>Ian Hammerstrom</h4>
          </div>
        </div>
        <div className="col-sm-6">
          <div style={teamMemberStyle} className="team-member">
            <img style={aboutUsProfileStyle} className="mx-auto rounded-circle" src="../img/jack_icon.jpeg" alt=""/>
            <h4>Jack Hamilton</h4>
          </div>
        </div>
        <div className="col-lg-8 mx-auto text-left">
          <p className="large text-muted" style={{marginLeft: "30px", marginTop:"30px" }}>
            jcat.bike was founded by Ian and Jack, two friends from Seattle, to bring folding bicycles more into mainstream US commuting and cycling culture.
          </p>
          <br/>
          <br/>
        </div>
      </div>
    </div>
  </section>
  )
}

const contactUsStyle = {
  // backgroundImage: 'url("../img/seattle3.jpg")',
  backgroundSize: 'cover',
  backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.3) 100%),url("../img/seattle3.jpg")',
}

const ContactUs = () => {
  return (
    <section id="contact" style={contactUsStyle }>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading" style={{ ...aboutUsHeadingStyle, color:'#FFD900'}}>Contact Us</h2>
            <br/>
          </div>
          <div className="col-lg-12 text-left" style={{ color: "white"} }>
              Currently, jcat.bike does not have a traditional brick & mortar location.
            <br/>
              Bikes are shown by appointment only in the Greenwood neighborhood of Seattle by contacting through one of the following options:
            <br/>
            <br/>
            <ul>
              <li>Email: <a style={{ color: '#FFD900'}} href="mailto: ride@jcat.bike"> ride@jcat.bike</a> </li>
              <li>Call/text normally or through Signal/WhatsApp: +1.206.658.3172</li>
            </ul>
            <br />
            <br />
          </div>
        </div>
      </div>
    </section>
  )
}

const footerStyle = {
  // marginTop: "50px",
  // marginBottom: "50px",
  // // textAlign: "center",
  height: '48px',
  backgroundColor: '#F4F4F4',
  backgroundSize: 'cover',
  // justifyContent: "center",
  fontSize: "12px",
}

// const containerCopyrightStyle = {
//   textAlign: "center",
//   // top: "50%",
//   // bottom: "50%",
//   // top: "50px",
//   height: "100%",
//   fontSize: "12px",
// }

// const containerRowStyle = {

// }

const Footer = () => {
  return(
    <section id="footer" style={footerStyle}>
      <div className="containercontainer h-100">
        <div className="row h-100 justify-content-center align-items-center" >
          Copyright &copy; jcat.bike LLC 2019
        </div >
      </div >
    </section>
  )
}


export default class HomePage extends React.Component {
  // state = {
  //   // users: [],
  //   // isLoading: true,
  // }

  render(){
    // if (this.state.isLoading){
    //   return (
    //     <LoadingGif style={loadingGifStyle}/>
    //   )
    // }

    // if not loading gif:
    return (
      <>
      <MyCarousel />
      <BikeDescription/>
      <AboutUs/>
      <ContactUs/>
      <Footer/>
      </>
      
    );
  }
}

// <li>{user.id}: {user.username} <img src={user.profileImage} alt="" style={profileImgStyle} ></img></li>

// render(){
//   return (
//     <div>
//       <h1>Home Page</h1>
//       <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
//         <div className="carousel-inner"></div>
//           {
//             this.state.users.map(user =>
//               <div className="carousel-item">
//                 <img src={user.profileImage} alt=""></img>
//                 <div className="carousel-caption d-none d-md-block">
//                   <h5> {user.id} </h5>
//                   <p> {user.username} </p>
//                 </div>
//               </div>
//             )
//           }
//         <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
//           <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//           <span className="sr-only">Previous</span>
//         </a>
//         <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
//           <span className="carousel-control-next-icon" aria-hidden="true"></span>
//           <span className="sr-only">Next</span>
//         </a>
//       </div>
//     </div>
//   )
// }
// }

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

